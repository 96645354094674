<template>
  <div class="HotelRoomType">
    <el-page-header @back="goBack" :content="nav" title=""> </el-page-header>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn1">
          <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="handleAddRoom"
            >添加房型</el-button
          >
        </div>
      </div>
      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="550"
          ref="table"
          :row-key="getRowKeys"
          :expand-row-keys="expands"
          @expand-change="toggleRowExpansion"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-form
                label-position="left"
                inline
                class="demo-table-expand"
                v-for="(item, i) in props.row.roomInfoList"
                :key="i"
              >
                <el-form-item
                  label="日期："
                  label-width="60px"
                  style="text-align: center"
                >
                  <span>{{ item.day }}</span>
                </el-form-item>
                <el-form-item
                  label="房间数量："
                  label-width="90px"
                  style="text-align: center"
                >
                  <span>{{ item.totalNum }}</span>
                </el-form-item>
                <el-form-item
                  label="房间剩余量："
                  label-width="100px"
                  style="text-align: center"
                >
                  <span>{{ item.remainingNum }}</span>
                </el-form-item>
                <el-form-item
                  label="操作:"
                  label-width="50px"
                  style="text-align: center"
                >
                  <template>
                    <el-button
                      size="mini"
                      @click="handleIncrease(i, props.row.roomInfoList[i])"
                      type="primary"
                      >增加库存</el-button
                    >
                    <el-button
                      size="mini"
                      @click="handleDecrease(i, props.row.roomInfoList[i])"
                      type="danger"
                      >减少库存</el-button
                    >
                  </template>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="房型名称" prop="roomType"> </el-table-column>
          <el-table-column label="房间价格">
            <template slot-scope="scope">
              <span>{{ scope.row.price / 100 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="房型描述" prop="descriptions">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="editHotelRoom(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                @click="handleAddRoomNum(scope.$index, scope.row)"
                >添加房间数量</el-button
              >
              <i
                class="iconfont"
                v-show="!scope.row.expansion"
                @click="expend(scope.row)"
                style="float: right"
                >&#xe60c;{{ "展开" }}</i
              >
              <i
                class="iconfont"
                v-show="scope.row.expansion"
                @click="expend(scope.row)"
                style="float: right"
                >&#xe62a;{{ "收起" }}</i
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <el-dialog
      title="添加房型"
      :visible.sync="dialogAddRoomVisible"
      center
      width="40%"
      @close="dialogAddRoomVisibleClose"
      class="roomDialog"
      :close-on-click-modal="false"
    >
      <div v-if="active == 0">
        <el-form
          :model="competitionRoom"
          ref="competitionRoom"
          label-position="right"
          label-width="100px"
        >
          <el-form-item
            label="房间名称"
            prop="roomType"
            :rules="[
              { required: true, message: '请输入房间名称', trigger: 'blur' },
            ]"
          >
            <el-input
              v-model="competitionRoom.roomType"
              autocomplete="off"
              style="width: 250px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="房间价格"
            prop="price"
            :rules="[
              { required: true, message: '请输入房间价格', trigger: 'blur' },
            ]"
          >
            <el-input-number
              :min="0"
              v-model="competitionRoom.price"
              autocomplete="off"
              style="width: 250px"
              :precision="2"
              :controls="false"
            ></el-input-number>
            <span style="margin-left: 10px">元</span>
          </el-form-item>
          <el-form-item label="房间照片">
            <el-upload
              class="upload-demo"
              action=""
              :before-remove="roomBeforeRemove"
              :on-remove="onRomeRmove"
              :on-change="getFileRoom"
              :http-request="uploadRoomImg"
              :limit="10"
              :on-exceed="handleRoomExceed"
              list-type="picture-card"
              :file-list="roomFileListCopy"
              :auto-upload="false"
            >
              <i class="iconfont iconfont-room">&#xe6e8;</i>
            </el-upload>
          </el-form-item>
          <el-form-item
            label="库存紧张限制"
            prop="inventoryLimit"
            :rules="[
              { required: true, message: '请输入库存限制', trigger: 'blur' },
            ]"
          >
            <el-input-number
              v-model="competitionRoom.inventoryLimit"
              autocomplete="off"
              style="width: 250px"
              :min="1"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            label="是否允许退款"
            prop="isAllowRefund"
            :rules="[
              {
                required: true,
                message: '请输入是否允许退款',
                trigger: 'blur',
              },
            ]"
          >
            <el-radio-group v-model="competitionRoom.isAllowRefund">
              <el-radio label="0">否</el-radio>
              <el-radio label="1">是</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <span style="height: 40px; line-height: 40px; margin-left: 12px"
          >房间配置</span
        >
        <el-checkbox-group v-model="roomConfigList">
          <div style="display: flex; margin-bottom: 10px; height: 40px">
            <div style="margin-left: 30px; display: flex; align-items: center">
              <div>
                <el-checkbox label="window" style="margin-right: 15px" disabled
                  >窗户</el-checkbox
                >
              </div>
              <div>
                <el-radio-group v-model="configRoomWindow">
                  <el-radio label="有窗">有窗</el-radio>
                  <el-radio label="无窗">无窗</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div style="margin-left: 79px; display: flex; align-items: center">
              <el-checkbox label="addBed" style="margin-right: 15px"
                >加床</el-checkbox
              >
              <div>
                <el-radio-group v-model="configRoomAddBed">
                  <el-radio label="可加床">可加床</el-radio>
                  <el-radio label="不可加床">不可加床</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div style="display: flex; margin-bottom: 10px; height: 40px">
            <div style="margin-left: 30px; width: 250px; display: flex">
              <el-checkbox label="bedInfo" disabled>床型</el-checkbox>
              <el-input
                v-model="configRoomBedInfo"
                autocomplete="off"
                style="width: 177px; margin-left: 10px"
                placeholder="例:1张1.5m大床"
              ></el-input>
            </div>
            <div style="margin-left: 30px; display: flex; align-items: center">
              <el-checkbox label="wifi" style="margin-right: 15px"
                >无线网络</el-checkbox
              >
              <div>
                <el-radio-group v-model="configRoomWifi">
                  <el-radio label="wifi免费">wifi免费</el-radio>
                  <el-radio label="无wifi">无wifi</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>

          <div style="display: flex; margin-bottom: 10px; height: 40px">
            <div
              style="
                margin-left: 30px;
                width: 250px;
                display: flex;
                align-items: center;
              "
            >
              <el-checkbox label="breakfast" style="margin-right: 15px" disabled
                >早餐</el-checkbox
              >
              <div>
                <el-radio-group v-model="configRoomBreakfast">
                  <el-radio label="有早餐">有早餐</el-radio>
                  <el-radio label="无早餐">无早餐</el-radio>
                </el-radio-group>
              </div>
            </div>

            <div style="margin-left: 30px; display: flex">
              <el-checkbox label="allowNum">可住人数</el-checkbox>
              <el-input
                v-model="configRoomAllowNum"
                autocomplete="off"
                style="width: 150px; margin-left: 10px"
                placeholder="例:可住两人"
              ></el-input>
            </div>
          </div>

          <div style="display: flex; height: 40px">
            <div style="margin-left: 30px; width: 250px; display: flex">
              <el-checkbox label="floor">楼层</el-checkbox>
              <el-input
                v-model="configRoomFloor"
                autocomplete="off"
                style="width: 177px; margin-left: 10px"
                placeholder="例:3-5层"
              ></el-input>
            </div>
            <div style="margin-left: 30px; width: 250px; display: flex">
              <el-checkbox label="roomSize">房间大小(㎡)</el-checkbox>
              <div style="display: flex">
                <el-input
                  v-model="configRoomSize"
                  autocomplete="off"
                  style="width: 125px; margin-left: 10px"
                ></el-input>
              </div>
            </div>
          </div>
        </el-checkbox-group>
        <div slot="footer" class="dialog-footer">
          <el-button style="margin-top: 12px" @click="next">下一步</el-button>
          <el-button type="primary" @click="dialogAddRoomVisible = false"
            >取消</el-button
          >
        </div>
      </div>
      <div v-if="active == 1">
        <span style="height: 40px; line-height: 40px; margin-left: 12px"
          >房间数量</span
        >
        <div
          style="display: flex; margin-left: 20px; margin-bottom: 10px"
          v-for="(item, i) in competitionRoom.roomInfo"
          :key="i"
        >
          <span style="height: 40px; line-height: 40px; margin-right: 15px"
            >日期</span
          >
          <el-date-picker
            v-model="item.time"
            type="date"
            placeholder="选择日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 200px; margin-right: 10px"
            :clearable="false"
            :picker-options="pickerOptions"
            :default-value="eDate"
          >
          </el-date-picker>
          <span style="height: 40px; line-height: 40px; margin-right: 5px"
            >房间总数</span
          >
          <el-input
            v-model="item.totalNum"
            autocomplete="off"
            style="width: 200px; margin-left: 10px"
          ></el-input>
          <el-button
            icon="el-icon-plus"
            circle
            style="margin-left: 10px"
            @click="addList"
          ></el-button>
          <el-button
            v-if="competitionRoom.roomInfo.length > 1"
            icon="el-icon-minus"
            circle
            style="margin-left: 10px"
            @click="removeList(item)"
          ></el-button>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button style="margin-top: 12px" @click="previous"
            >上一步</el-button
          >
          <el-button type="primary" @click="pushRoomType">提交</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="编辑房型"
      :visible.sync="dialogEditRoomVisible"
      center
      width="40%"
      class="roomDialog"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      @close="dialogEditRoomVisibleClose"
    >
      <el-form
        :model="editRoomTable"
        ref="editRoomTable"
        label-position="right"
        label-width="80px"
      >
        <el-form-item
          label="房间名称"
          prop="roomType"
          :rules="[
            { required: true, message: '请输入房间名称', trigger: 'blur' },
          ]"
        >
          <el-input
            v-model="editRoomTable.roomType"
            autocomplete="off"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="房间价格"
          prop="price"
          :rules="[
            { required: true, message: '请输入房间价格', trigger: 'blur' },
          ]"
        >
          <el-input-number
            v-model="editRoomTable.price"
            autocomplete="off"
            style="width: 250px"
            :precision="2"
            :controls="false"
          ></el-input-number>
          <span style="margin-left: 10px">元</span>
        </el-form-item>
        <el-form-item label="房间照片">
          <el-upload
            class="upload-demo"
            action=""
            :before-remove="roomBeforeRemove"
            :on-remove="onRomeEditRmove"
            :on-change="getFileEditRoom"
            :http-request="uploadRoomImg"
            :limit="10"
            :on-exceed="handleRoomExceed"
            list-type="picture-card"
            :file-list="roomFileEditListCopy"
            :auto-upload="false"
          >
            <i class="iconfont iconfont-room">&#xe6e8;</i>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="库存紧张限制"
          prop="inventoryLimit"
          :rules="[
            { required: true, message: '请输入库存限制', trigger: 'blur' },
          ]"
        >
          <el-input-number
            v-model="editRoomTable.inventoryLimit"
            autocomplete="off"
            style="width: 250px"
            :min="1"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="是否允许退款"
          prop="isAllowRefund"
          :rules="[
            {
              required: true,
              message: '请输入是否允许退款',
              trigger: 'blur',
            },
          ]"
        >
          <el-radio-group v-model="editRoomTable.isAllowRefund">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span style="height: 40px; line-height: 40px; margin-left: 12px"
        >房间配置</span
      >
      <el-checkbox-group v-model="roomConfigListEdit">
        <div style="display: flex; margin-bottom: 10px; height: 40px">
          <div style="margin-left: 30px; display: flex; align-items: center">
            <el-checkbox label="window" style="margin-right: 15px" disabled
              >窗户</el-checkbox
            >
            <div>
              <el-radio-group v-model="configRoomWindowEdit">
                <el-radio label="有窗">有窗</el-radio>
                <el-radio label="无窗">无窗</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div style="margin-left: 79px; display: flex; align-items: center">
            <el-checkbox label="addBed" style="margin-right: 15px"
              >加床</el-checkbox
            >
            <div>
              <el-radio-group v-model="configRoomAddBedEdit">
                <el-radio label="可加床">可加床</el-radio>
                <el-radio label="不可加床">不可加床</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div style="display: flex; margin-bottom: 10px; height: 40px">
          <div style="margin-left: 30px; width: 250px; display: flex">
            <el-checkbox label="bedInfo" disabled>床型</el-checkbox>
            <el-input
              v-model="configRoomBedInfoEdit"
              autocomplete="off"
              style="width: 177px; margin-left: 10px"
              placeholder="例:1张1.5m大床"
            ></el-input>
          </div>
          <div style="margin-left: 30px; display: flex; align-items: center">
            <el-checkbox label="wifi" style="margin-right: 15px"
              >无线网络</el-checkbox
            >
            <div>
              <el-radio-group v-model="configRoomWifiEdit">
                <el-radio label="wifi免费">wifi免费</el-radio>
                <el-radio label="无wifi">无wifi</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>

        <div style="display: flex; margin-bottom: 10px; height: 40px">
          <div
            style="
              margin-left: 30px;
              width: 250px;
              display: flex;
              align-items: center;
            "
          >
            <el-checkbox label="breakfast" style="margin-right: 15px" disabled
              >早餐</el-checkbox
            >
            <div>
              <el-radio-group v-model="configRoomBreakfastEdit">
                <el-radio label="有早餐">有早餐</el-radio>
                <el-radio label="无早餐">无早餐</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div style="margin-left: 30px; display: flex">
            <el-checkbox label="allowNum">可住人数</el-checkbox>
            <el-input
              v-model="configRoomAllowNumEdit"
              autocomplete="off"
              style="width: 150px; margin-left: 10px"
              placeholder="例:可住两人"
            ></el-input>
          </div>
        </div>

        <div style="display: flex; height: 40px">
          <div style="margin-left: 30px; width: 250px; display: flex">
            <el-checkbox label="floor">楼层</el-checkbox>
            <el-input
              v-model="configRoomFloorEdit"
              autocomplete="off"
              style="width: 177px; margin-left: 10px"
              placeholder="例:3-5层"
            ></el-input>
          </div>
          <div style="margin-left: 30px; width: 250px; display: flex">
            <el-checkbox label="roomSize">房间大小(㎡)</el-checkbox>
            <el-input
              v-model="configRoomSizeEdit"
              autocomplete="off"
              style="width: 125px; margin-left: 10px"
            ></el-input>
          </div>
        </div>
      </el-checkbox-group>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogEditRoomVisible = false"
          >取消</el-button
        >
        <el-button style="margin-top: 12px" @click="pushEditRoom"
          >提交</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="添加房间数量(注:同一日期只能添加一次)"
      :visible.sync="dialogAddRoomNumVisible"
      center
      width="40%"
      class="roomDialog"
      :close-on-click-modal="false"
    >
      <span style="height: 40px; line-height: 40px; margin-left: 12px"
        >房间数量</span
      >
      <div
        style="display: flex; margin-left: 20px; margin-bottom: 10px"
        v-for="(item, i) in addRoomNum.roomInfoList"
        :key="i"
      >
        <span style="height: 40px; line-height: 40px; margin-right: 15px"
          >日期</span
        >
        <el-date-picker
          v-model="item.time"
          type="date"
          placeholder="选择日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          style="width: 200px; margin-right: 10px"
          :clearable="false"
          :picker-options="pickerOptions"
          :default-value="eDate"
        >
        </el-date-picker>
        <span style="height: 40px; line-height: 40px; margin-right: 5px"
          >房间总数</span
        >
        <el-input
          v-model="item.totalNum"
          autocomplete="off"
          style="width: 200px; margin-left: 10px"
        ></el-input>
        <el-button
          icon="el-icon-plus"
          circle
          style="margin-left: 10px"
          @click="addListNum"
        ></el-button>
        <el-button
          v-if="addRoomNum.roomInfoList.length > 1"
          icon="el-icon-minus"
          circle
          style="margin-left: 10px"
          @click="removeAddList(item)"
        ></el-button>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addRoomNumList">提交</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="新增库存"
      :visible.sync="dialogIncreaseVisible"
      center
      width="30%"
      :close-on-click-modal="false"
    >
      <span style="height: 40px; line-height: 40px; margin-left: 100px"
        >新增数量:</span
      >
      <el-input-number
        v-model="increaseNum"
        autocomplete="off"
        style="width: 200px; margin-left: 10px"
        :min="1"
      ></el-input-number>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogIncreaseVisible = false">取 消</el-button>
        <el-button type="primary" @click="increase">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="减少库存"
      :visible.sync="dialogDecreaseVisible"
      center
      width="30%"
      :close-on-click-modal="false"
    >
      <span style="height: 40px; line-height: 40px; margin-left: 100px"
        >减少数量:</span
      >
      <el-input-number
        v-model="decreaseNum"
        autocomplete="off"
        style="width: 200px; margin-left: 10px"
        :min="1"
      ></el-input-number>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogDecreaseVisible = false">取 消</el-button>
        <el-button type="primary" @click="decrease">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    let eDate = new Date(
      this.$moment(this.$route.params.eDate).format("YYYY-MM-DD")
    );
    let lDate = new Date(
      this.$moment(this.$route.params.lDate).format("YYYY-MM-DD")
    );
    let date1 = new Date(eDate.setDate(eDate.getDate() - 1));
    let date2 = new Date(lDate.setDate(lDate.getDate() - 1));
    return {
      pickerOptions: {
        disabledDate(time) {
          if (date1 && date2) {
            return (
              time.getTime() <= date1.getTime() ||
              time.getTime() > date2.getTime()
            );
          }
        },
      },
      getRowKeys(row) {
        return row.id;
      },
      expands: [],
      nav: this.$route.params.back,
      tableData: [],
      roomFileList: [],
      roomFileListCopy: [],
      roomFileEditList: [],
      roomFileEditListCopy: [],
      active: 0,
      competitionRoom: {
        roomInfo: [
          {
            time: "",
            totalNum: "",
          },
        ],
        roomConfigList: [],
        roomConfig: {},
        isAllowRefund: "",
      },
      editRoomTable: {
        // isAllowRefund: "",
      },
      addRoomNum: {
        roomInfoList: [
          {
            time: "",
            totalNum: "",
          },
        ],
      },
      roomConfigList: ["window", "bedInfo", "breakfast"],
      roomConfigListEdit: ["window", "bedInfo", "breakfast"],
      dialogAddRoomVisible: false,
      dialogEditRoomVisible: false,
      dialogAddRoomNumVisible: false,
      dialogIncreaseVisible: false,
      dialogDecreaseVisible: false,
      roomFileName: "",
      roomFile: "",
      roomPath: "",
      roomUrlList: [],
      hotelId: this.$route.params.hotelId,
      choseId: "",
      pictures: [],
      increaseNum: 1,
      decreaseNum: 1,
      roomInfoId: 0,
      configRoomSize: "",
      configRoomWindow: "有窗",
      configRoomBedInfo: "",
      configRoomWifi: "",
      configRoomFloor: "",
      configRoomAllowNum: "",
      configRoomBreakfast: "有早餐",
      configRoomAddBed: "",
      configRoomSizeEdit: "",
      configRoomWindowEdit: "",
      configRoomBedInfoEdit: "",
      configRoomWifiEdit: "",
      configRoomFloorEdit: "",
      configRoomAllowNumEdit: "",
      configRoomBreakfastEdit: "",
      configRoomAddBedEdit: "",
      fileArrayList: [],
      fileArrayEditList: [],
      fileArrayEditListCopy: [],
      hasFmt: false,
      hasFmt2: true,
      num: 1,
      eDate: this.$moment(this.$route.params.eDate).format("YYYY-MM-DD"),
    };
  },
  created() {
    this.getTableData();
    // console.log(this.eDate)
  },
  inject: ["reload"],
  methods: {
    expend(row) {
      let $table = this.$refs.table;
      this.tableData.map((item) => {
        if (row.id != item.id) {
          $table.toggleRowExpansion(item, false);
          item.expansion = false;
        } else {
          item.expansion = !item.expansion;
        }
      });
      $table.toggleRowExpansion(row);
    },
    handleIncrease(index, row) {
      this.increaseNum = 1;
      this.roomInfoId = row.id;
      this.dialogIncreaseVisible = true;
    },
    //减少库存按钮
    handleDecrease(index, row) {
      this.decreaseNum = 1;
      this.roomInfoId = row.id;
      this.dialogDecreaseVisible = true;
    },
    //提交增加酒店房型库存量
    async increase() {
      this.dialogIncreaseVisible = false;
      try {
        const result = await this.$api.addInventory(
          `roomInfoId=${this.roomInfoId}&action=add&num=${this.increaseNum}&isAllowRefund=${this.isAllowRefund}`
        );
        if (result.data.status_code == "200") {
          this.$message({
            type: "success",
            message: "添加成功",
          });
          this.getTableData();
        } else {
          this.$message({
            type: "warning",
            message: result.data.msg,
          });
        }
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
    //提交减少酒店房型库存量
    async decrease(index, row) {
      this.dialogDecreaseVisible = false;
      try {
        const result = await this.$api.addInventory(
          `roomInfoId=${this.roomInfoId}&action=reduce&num=${this.decreaseNum}`
        );
        if (result.data.status_code == "200") {
          this.$message({
            type: "success",
            message: "减少成功",
          });
          this.getTableData();
        } else {
          this.$message({
            type: "warning",
            message: result.data.msg,
          });
        }
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
    //编辑酒店房型前的数据处理
    whetherChose(item) {
      if (item.roomSize && item.roomSize.value) {
        var numArr = item.roomSize.value.match(/\d+/g).join(""); //正则只留下数字
        var numArr1 = item.roomSize.value.match(/\d+\.\d+/g); //正则小数点情况下只留下数字
        if (numArr1) {
          numArr1 = numArr1.join("");
          this.configRoomSizeEdit = numArr1;
        } else {
          this.configRoomSizeEdit = numArr;
        }
      }
      if (item.window && item.window.value) {
        this.configRoomWindowEdit = item.window.value;
      }
      if (item.bedInfo && item.bedInfo.value) {
        this.configRoomBedInfoEdit = item.bedInfo.value;
      }
      if (item.wifi && item.wifi.value) {
        this.configRoomWifiEdit = item.wifi.value;
      }
      if (item.floor && item.floor.value) {
        this.configRoomFloorEdit = item.floor.value;
      }
      if (item.allowNum && item.allowNum.value) {
        this.configRoomAllowNumEdit = item.allowNum.value;
      }
      if (item.breakfast && item.breakfast.value) {
        this.configRoomBreakfastEdit = item.breakfast.value;
      }
      if (item.addBed && item.addBed.value) {
        this.configRoomAddBedEdit = item.addBed.value;
      }
    },
    toggleRowExpansion(row) {
      console.log(row);
      if (this.choseId == row.id) {
        this.expands = [];
        this.choseId = "";
        return;
      }
      this.expands = [];
      this.expands.push(row.id);
      this.choseId = row.id;
    },
    //返回home页面
    goBack() {
      this.$router.go(-1);
      // this.$router.push({
      //   name: "Hotel",
      //   params: {
      //     back: this.$route.params.back,
      //     id: this.$route.params.id,
      //     name: "1",
      //     pageSize: this.$route.params.pageSize,
      //     currentPage: this.$route.params.currentPage,
      //     pageSizeTwo: this.$route.params.pageSizeTwo,
      //     currentPageTwo: this.$route.params.currentPageTwo,
      //   },
      // });
    },
    //获取酒店房型信息
    async getTableData() {
      try {
        const result = await this.$api.getRoomType(`hotelId=${this.hotelId}`);
        this.tableData = result;
        this.totalCount = result.length;
      } catch (error) {
        console.log(error);
      }
    },
    //新增房型前的数据处理
    handleAddRoom() {
      this.dialogAddRoomVisible = true;
    },
    //删除酒店房间数量(新增房型里)
    removeList(item) {
      var index = this.competitionRoom.roomInfo.indexOf(item);
      if (index !== -1) {
        this.competitionRoom.roomInfo.splice(index, 1);
      }
    },
    //单独删除酒店房间数量
    removeAddList(item) {
      var index = this.addRoomNum.roomInfoList.indexOf(item);
      if (index !== -1) {
        this.addRoomNum.roomInfoList.splice(index, 1);
      }
    },
    //添加酒店房间数量(新增房型里)
    addList() {
      this.competitionRoom.roomInfo.push({
        time: "",
        totalNum: "",
      });
    },
    //单独添加酒店房间数量
    addListNum() {
      this.addRoomNum.roomInfoList.push({
        time: "",
        totalNum: "",
      });
    },
    //房型添加下一步
    next() {
      for (let item of this.roomConfigList) {
        if (item == "roomSize" && !this.configRoomSize) {
          this.$message({
            type: "warning",
            message: "请输入勾选配置信息",
          });
          return;
        } else if (item == "bedInfo" && !this.configRoomBedInfo) {
          this.$message({
            type: "warning",
            message: "请输入勾选配置信息",
          });
          return;
        } else if (item == "floor" && !this.configRoomFloor) {
          this.$message({
            type: "warning",
            message: "请输入勾选配置信息",
          });
          return;
        } else if (item == "addBed" && !this.configRoomAddBed) {
          this.$message({
            type: "warning",
            message: "请输入勾选配置信息",
          });
          return;
        } else if (item == "wifi" && !this.configRoomWifi) {
          this.$message({
            type: "warning",
            message: "请输入勾选配置信息",
          });
          return;
        } else if (item == "allowNum" && !this.configRoomAllowNum) {
          this.$message({
            type: "warning",
            message: "请输入勾选配置信息",
          });
          return;
        }
      }
      if (!this.hasFmt) {
        this.$message({
          type: "warning",
          message: "请上传至少一张照片",
        });
        return;
      }
      if (this.active == 0) {
        this.$refs.competitionRoom.validate((valid) => {
          if (valid) {
            this.active = 1;
            this.roomFileListCopy = this.roomFileList;
          } else {
            return false;
          }
        });
      }
    },
    //房型添加上一步
    previous() {
      if (this.active == 1) {
        this.active = 0;
        // this.roomFileListCopy = this.roomFileList
      }
    },
    //编辑酒店房型前的数据处理
    editHotelRoom(index, row) {
      this.dialogEditRoomVisible = true;
      this.editRoomTable.isAllowRefund = row.isAllowRefund;
      this.roomFileEditList = [];
      this.roomFileEditListCopy = [];
      this.editRoomTable = JSON.parse(JSON.stringify(row));
      this.editRoomTable.pictures = JSON.parse(this.editRoomTable.pictures);
      this.roomUrlList = this.editRoomTable.pictures;
      this.editRoomTable.roomConfig = JSON.parse(this.editRoomTable.roomConfig);
      console.log(this.editRoomTable.roomConfig);
      this.editRoomTable.price /= 100;
      for (const i in this.editRoomTable.roomConfig) {
        if (this.editRoomTable.roomConfig[i].value) {
          this.roomConfigListEdit.push(this.editRoomTable.roomConfig[i].code);
        }
      }
      this.whetherChose(this.editRoomTable.roomConfig);
      if (this.editRoomTable.pictures && this.roomFileEditList.length == 0) {
        let list1 = this.editRoomTable.pictures.map((item) => ({ url: item }));
        let list2 = list1.map((item) => ({ url: item.url }));
        console.log(list1, list2);
        this.roomFileEditListCopy = list1;
        this.roomFileEditList = list2;
      }
      this.editRoomTable.roomConfig = {};
    },
    //提交编辑后的房型数据
    pushEditRoom() {
      for (let item of this.roomConfigListEdit) {
        if (item == "roomSize" && !this.configRoomSizeEdit) {
          this.$message({
            type: "warning",
            message: "请输入勾选配置信息",
          });
          return;
        } else if (item == "bedInfo" && !this.configRoomBedInfoEdit) {
          this.$message({
            type: "warning",
            message: "请输入勾选配置信息",
          });
          return;
        } else if (item == "floor" && !this.configRoomFloorEdit) {
          this.$message({
            type: "warning",
            message: "请输入勾选配置信息",
          });
          return;
        } else if (item == "addBed" && !this.configRoomAddBedEdit) {
          this.$message({
            type: "warning",
            message: "请输入勾选配置信息",
          });
          return;
        } else if (item == "wifi" && !this.configRoomWifiEdit) {
          this.$message({
            type: "warning",
            message: "请输入勾选配置信息",
          });
          return;
        } else if (item == "allowNum" && !this.configRoomAllowNumEdit) {
          this.$message({
            type: "warning",
            message: "请输入勾选配置信息",
          });
          return;
        }
      }
      if (!this.hasFmt2) {
        this.$message({
          type: "warning",
          message: "请上传至少一张照片",
        });
        return;
      }
      this.$refs.editRoomTable.validate(async (valid) => {
        if (valid) {
          delete this.editRoomTable.saveCompetition;
          delete this.editRoomTable.updateCompetition;
          this.roomConfigListEdit.forEach((item) => {
            if (item == "roomSize") {
              let obj = new Object();
              obj.code = item;
              obj.value = this.configRoomSizeEdit
                ? this.configRoomSizeEdit + "㎡"
                : "";
              this.editRoomTable.roomConfig.roomSize = obj;
            } else if (item == "window") {
              let obj = new Object();
              obj.code = item;
              obj.value = this.configRoomWindowEdit
                ? this.configRoomWindowEdit
                : "";
              this.editRoomTable.roomConfig.window = obj;
            } else if (item == "bedInfo") {
              let obj = new Object();
              obj.code = item;
              obj.value = this.configRoomBedInfoEdit
                ? this.configRoomBedInfoEdit
                : "";
              this.editRoomTable.roomConfig.bedInfo = obj;
            } else if (item == "wifi") {
              let obj = new Object();
              obj.code = item;
              obj.value = this.configRoomWifiEdit
                ? this.configRoomWifiEdit
                : "";
              this.editRoomTable.roomConfig.wifi = obj;
            } else if (item == "floor") {
              let obj = new Object();
              obj.code = item;
              obj.value = this.configRoomFloorEdit
                ? this.configRoomFloorEdit
                : "";
              this.editRoomTable.roomConfig.floor = obj;
            } else if (item == "allowNum") {
              let obj = new Object();
              obj.code = item;
              obj.value = this.configRoomAllowNumEdit
                ? this.configRoomAllowNumEdit
                : "";
              this.editRoomTable.roomConfig.allowNum = obj;
            } else if (item == "breakfast") {
              let obj = new Object();
              obj.code = item;
              obj.value = this.configRoomBreakfastEdit
                ? this.configRoomBreakfastEdit
                : "";
              this.editRoomTable.roomConfig.breakfast = obj;
            } else if (item == "addBed") {
              let obj = new Object();
              obj.code = item;
              obj.value = this.configRoomAddBedEdit
                ? this.configRoomAddBedEdit
                : "";
              this.editRoomTable.roomConfig.addBed = obj;
            }
          });
          console.log(this.editRoomTable.roomConfig);
          this.editRoomTable.roomConfig = JSON.stringify(
            this.editRoomTable.roomConfig
          );
          this.editRoomTable.competitionId = this.$route.params.id;
          this.editRoomTable.roomInfo = JSON.stringify(
            this.editRoomTable.roomInfoList
          );
          this.editRoomTable.pictures = this.roomUrlList;
          this.editRoomTable.pictures = JSON.stringify(
            this.editRoomTable.pictures
          );
          this.editRoomTable.price *= 100;
          delete this.editRoomTable.roomInfoList;
          try {
            const result = await this.$api.editRoomInfo(this.editRoomTable);
            if (result.data.status_code == "200") {
              this.$message({
                type: "success",
                message: "编辑成功",
              });
              this.dialogEditRoomVisible = false;
              // this.getTableData()
            } else {
              this.$message({
                type: "warning",
                message: result.data.msg,
              });
            }
            console.log(result);
          } catch (error) {
            console.log(error);
          }
        } else {
          return false;
        }
      });
    },
    //提交新增的酒店房型信息
    async pushRoomType() {
      for (const key of this.competitionRoom.roomInfo) {
        if (!key["time"] || !key["totalNum"]) {
          this.$message({
            type: "warning",
            message: "请选择日期和输入房间数量",
          });
          return;
        }
      }
      this.dialogAddRoomVisible = false;
      this.competitionRoom.hotelId = this.hotelId;
      this.competitionRoom.competitionId = this.id;
      this.roomConfigList.forEach((item) => {
        if (item == "roomSize") {
          let obj = new Object();
          obj.code = item;
          obj.value = this.configRoomSize ? this.configRoomSize + "㎡" : "";
          this.competitionRoom.roomConfig.roomSize = obj;
        } else if (item == "window") {
          let obj = new Object();
          obj.code = item;
          obj.value = this.configRoomWindow ? this.configRoomWindow : "";
          this.competitionRoom.roomConfig.window = obj;
        } else if (item == "bedInfo") {
          let obj = new Object();
          obj.code = item;
          obj.value = this.configRoomBedInfo ? this.configRoomBedInfo : "";
          this.competitionRoom.roomConfig.bedInfo = obj;
        } else if (item == "wifi") {
          let obj = new Object();
          obj.code = item;
          obj.value = this.configRoomWifi ? this.configRoomWifi : "";
          this.competitionRoom.roomConfig.wifi = obj;
        } else if (item == "floor") {
          let obj = new Object();
          obj.code = item;
          obj.value = this.configRoomFloor ? this.configRoomFloor : "";
          this.competitionRoom.roomConfig.floor = obj;
        } else if (item == "allowNum") {
          let obj = new Object();
          obj.code = item;
          obj.value = this.configRoomAllowNum ? this.configRoomAllowNum : "";
          this.competitionRoom.roomConfig.allowNum = obj;
        } else if (item == "breakfast") {
          let obj = new Object();
          obj.code = item;
          obj.value = this.configRoomBreakfast ? this.configRoomBreakfast : "";
          this.competitionRoom.roomConfig.breakfast = obj;
        } else if (item == "addBed") {
          let obj = new Object();
          obj.code = item;
          obj.value = this.configRoomAddBed ? this.configRoomAddBed : "";
          this.competitionRoom.roomConfig.addBed = obj;
        }
      });

      this.competitionRoom.roomConfig = JSON.stringify(
        this.competitionRoom.roomConfig
      );
      this.competitionRoom.roomInfo = JSON.stringify(
        this.competitionRoom.roomInfo
      );

      this.competitionRoom.pictures = this.roomUrlList;
      this.competitionRoom.pictures = JSON.stringify(
        this.competitionRoom.pictures
      );
      this.competitionRoom.competitionId = this.$route.params.id;
      this.competitionRoom.price *= 100;
      console.log(this.competitionRoom);
      try {
        const result = await this.$api.addRoomType(this.competitionRoom);
        if (result.data.status_code == "200") {
          this.$message({
            type: "success",
            message: "新增成功",
          });
          this.getTableData();
        } else {
          this.$message({
            type: "warning",
            message: result.data.msg,
          });
        }
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
    //添加房间数量前的数据处理
    handleAddRoomNum(index, row) {
      console.log(row);
      this.dialogAddRoomNumVisible = true;
      this.addRoomNum.roomId = row.id;
    },
    //提交添加的不同日期的房间数量
    async addRoomNumList(index, row) {
      for (const key of this.addRoomNum.roomInfoList) {
        if (!key["time"] || !key["totalNum"]) {
          this.$message({
            type: "warning",
            message: "请选择日期和输入房间数量",
          });
          return;
        }
      }

      this.addRoomNum.roomInfoList = JSON.stringify(
        this.addRoomNum.roomInfoList
      );
      this.dialogAddRoomNumVisible = false;
      try {
        const result = await this.$api.addRoomNum(this.addRoomNum);
        if (result.data.status_code == "200") {
          this.$message({
            type: "success",
            message: "添加成功",
          });
          this.getTableData();
        } else {
          this.$message({
            type: "warning",
            message: result.data.msg,
          });
        }
        console.log(result);
        this.addRoomNum = {
          roomInfoList: [
            {
              time: "",
              totalNum: "",
            },
          ],
        };
      } catch (error) {
        console.log(error);
      }
    },
    //新增酒店房型弹框关闭后的数据清空
    dialogAddRoomVisibleClose() {
      setTimeout(() => {
        this.reload();
      }, 500);

      // this.active = 0
      // this.competitionRoom = {
      //   roomConfigList: [],
      //   roomInfo: [
      //     {
      //       time: "",
      //       totalNum: "",
      //     },
      //   ],
      // }
      // this.roomConfigList = ["window", "bedInfo", "breakfast"]
      // this.roomFileList = []
      // this.roomFileListCopy = []
      // this.roomUrlList = []
    },
    //编辑酒店房型弹框关闭后的按钮重置
    dialogEditRoomVisibleClose() {
      setTimeout(() => {
        this.reload();
      }, 100);
      // this.roomConfigListEdit = ["window", "bedInfo", "breakfast"]
      // this.configRoomSizeEdit = ""
      // this.configRoomWindowEdit = ""
      // this.configRoomBedInfoEdit = ""
      // this.configRoomWifiEdit = ""
      // this.configRoomFloorEdit = ""
      // this.configRoomAllowNumEdit = ""
      // this.configRoomBreakfastEdit = ""
      // this.configRoomAddBedEdit = ""
      // this.roomUrlList = []
    },
    //上传酒店房间图片
    async uploadRoomImg() {
      try {
        const result = await this.$api.uploadImg(
          `fileName=${this.roomFileName}&type=room`
        ); //获取上传配置信息
        // console.log(result)
        this.roomPath = result.showDomain; //保存配置信息
        // 2、设置上传配置
        let formData = new FormData();
        formData.append("authorization", result.authorization);
        formData.append("policy", result.policy);
        formData.append("file", this.roomFile);
        this.$http
          .post(result.commitPath, formData, {
            timeout: 3600000,
            onUploadProgress: (e) => {
              if (e.lengthComputable) {
                let rate = ((e.loaded * 100) / e.total).toFixed(2);
                // console.log(rate)
                if (rate < 100) {
                }
              }
            },
          })
          .then((res) => {
            // console.log(res)
            this.roomUrlList.push(this.roomPath + res.data.url); //获取单张图片的url后进行数组形式的存储
            if (this.dialogAddRoomVisible) {
              this.roomFileList.push({ url: this.roomPath + res.data.url });
              console.log(this.roomFileList, this.roomUrlList);
            }
            if (this.dialogEditRoomVisible) {
              this.roomFileEditList.push({ url: this.roomPath + res.data.url });
              console.log(this.roomFileEditList, this.roomUrlList);
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    //酒店房间照片移除前的提示
    roomBeforeRemove() {
      return this.$confirm(`确定移除照片吗？`);
    },
    //移除房间照片后的钩子函数(新增)
    onRomeRmove(file, fileList) {
      console.log(file, fileList);
      for (let i = 0; i < this.fileArrayList.length; i++) {
        if (file.url == this.fileArrayList[i].url) {
          this.roomFileList.splice(i, 1);
          this.roomUrlList.splice(i, 1);
          this.fileArrayList.splice(i, 1);
        } else if (file.url == this.roomFileList[i].url) {
          this.roomFileList.splice(i, 1);
          this.roomUrlList.splice(i, 1);
          this.fileArrayList.splice(i, 1);
        }
      }
      if (fileList.length == 0) {
        this.hasFmt = false;
      }
      console.log(this.roomFileList, this.roomUrlList);
    },
    //移除房间照片后的钩子函数(编辑)
    onRomeEditRmove(file, fileList) {
      console.log(file, fileList);
      let length = fileList.length;
      console.log(length);
      for (let i = 0; i < this.roomFileEditList.length; i++) {
        if (file.status == "success") {
          if (file.url == this.roomFileEditList[i].url) {
            this.roomFileEditList.splice(i, 1);
            this.roomUrlList.splice(i, 1);
            console.log(this.roomFileEditList, this.roomUrlList);
          }
        }
      }
      for (let j = 0; j < this.fileArrayEditListCopy.length; j++) {
        if (file.url == this.fileArrayEditListCopy[j].url) {
          console.log("相等了");
          this.roomFileEditList.splice(j + length - 1, 1);
          this.roomUrlList.splice(j + length - 1, 1);
          this.fileArrayEditListCopy.splice(j, 1);
          console.log(this.roomFileEditList, this.roomUrlList);
        }
      }
      if (fileList.length == 0) {
        this.hasFmt2 = false;
      }
    },
    //添加房间照片后的钩子函数(新增)
    getFileRoom(file, fileList) {
      console.log("上传状态改变了", file, fileList);
      this.fileArrayList.push(file);
      this.roomFileName = file.name;
      this.roomFile = file.raw;
      if (fileList.length > 0) {
        this.hasFmt = true;
      }
      this.uploadRoomImg();
    },
    //添加房间照片后的钩子函数(编辑)
    getFileEditRoom(file, fileList) {
      console.log("上传状态改变了", file);
      this.fileArrayEditListCopy.push(file);
      console.log(this.fileArrayEditListCopy);
      this.roomFileName = file.name;
      this.roomFile = file.raw;
      if (fileList.length > 0) {
        this.hasFmt2 = true;
      }
      this.uploadRoomImg();
    },
    handleRoomExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择4个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
  },
};
</script>

<style lang="less">
@font-face {
  font-family: "iconfont"; /* Project id 2817395 */
  src: url("//at.alicdn.com/t/font_2817395_n3eegmhdpfs.woff2?t=1631849645635")
      format("woff2"),
    url("//at.alicdn.com/t/font_2817395_n3eegmhdpfs.woff?t=1631849645635")
      format("woff"),
    url("//at.alicdn.com/t/font_2817395_n3eegmhdpfs.ttf?t=1631849645635")
      format("truetype");
}
.HotelRoomType {
  .iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &:hover {
      cursor: pointer;
    }
  }
  .iconfont-room {
    font-size: 48px;
  }
  .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 120px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 25%;
  }
  .el-table__expand-column .cell {
    display: none;
  }
  .roomDialog {
    .el-dialog {
      min-height: 700px;
      min-width: 800px;
    }
  }
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn1 {
        display: flex;
        // float: right;
        .select1 {
          width: 150px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .select2 {
      width: 100px;
      margin-right: 20px;
    }
    .btn2 {
      display: flex;
      margin: 20px 0;
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .el-dialog__header {
    text-align: center;
  }
  .dialog-footer {
    text-align: right;
    // position: absolute;
    // bottom: 10px;
    // right: 10px;
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
